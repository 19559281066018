<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { convertPrice, convertDateTime } from "@/_helpers";
import skeletonLoading from "@/components/skeleton";

export default {
  components: { Layout, PageHeader, skeletonLoading },
  page: {
    title: "Detail Order",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Detail Order",
          active: true,
        },
      ],
      isLoading: false,
      apiUrl: {
        order: process.env.VUE_APP_API_BASE_URL + "orders",
        orderDetail: process.env.VUE_APP_API_BASE_URL + "order-details",
        orderPaymentLog:
          process.env.VUE_APP_API_BASE_URL + "order-payment-logs",
        baseUrl: process.env.VUE_APP_BASE_URL,
      },
      setData: [],
      columns: [
        {
          text: "No. Order",
          value: "order_number",
        },
        {
          text: "Pelanggan",
        },
        {
          text: "Pembayaran",
        },
        {
          text: "Dibuat Oleh",
        },
        {
          text: "Diperbarui Oleh",
        },
        {
          text: "Status",
        },
      ],
      tableTd: 5,
      columnsTable: [
        {
          key: "kategori_Pakaian",
          label: "Kategori Pakaian",
        },
        {
          key: "Warna",
          label: "Warna",
        },
        {
          key: "Ukuran",
          label: "ukuran",
        },
        {
          key: "Harga",
          label: "Harga",
        },
        {
          key: "Kuantitas",
          label: "Kuantitas",
        },
        {
          key: "Total",
          label: "Total",
        },
      ],
      screenWidth: 0,
      paymentOrder: {
        order_id: this.$route.params.id,
        payment_method: "down payment",
        status: "approved",
        type: "debit",
        total: null,
        note: null,
        attachment: null,
      },
    };
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    this.getDetail();
    this.updateScreenWidth();
    this.onScreenResize();
  },
  methods: {
    formatPrice(value) {
      if (value) {
        return convertPrice(value);
      }
    },
    formatDate(value) {
      if (value) {
        return convertDateTime(value);
      }
    },
    paymentOrderModal() {
      this.$refs["payment-order-modal"].show();
    },
    hidePaymentOrderModal() {
      this.$refs["payment-order-modal"].hide();
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    countUniqueSizes(clothColors) {
      return clothColors.reduce(
        (acc, color) => acc + color.cloth_sizes.length,
        0
      );
    },
    paymentOrderSubmit() {
      this.paymentOrder.payment_method = "add_payment";
      this.$http
        .post(this.apiUrl.orderPaymentLog, this.paymentOrder)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch(
            "notification/success",
            "Pembayran Berhasil Ditambahkan"
          );
          this.paymentOrder.total = null;
          this.paymentOrder.note = null;
          this.paymentOrder.attachment = null;
          this.$refs["payment-order-modal"].hide();
          this.getDetail();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
    },
    calculateTotalQty(clothColor) {
      let totalQty = 0;
      totalQty = clothColor.reduce((accColor, color) => {
        return (
          accColor +
          color.cloth_sizes.reduce((accSize, size) => {
            return accSize + size.qty;
          }, 0)
        );
      }, 0);

      return totalQty;
    },
    calculateTotalPrice(clothColor) {
      let totalPrice = 0;
      totalPrice = clothColor.reduce((accColor, color) => {
        return (
          accColor +
          color.cloth_sizes.reduce((accSize, size) => {
            return accSize + size.qty * size.price;
          }, 0)
        );
      }, 0);
      return totalPrice;
    },
    setCancleOrder() {
      this.isLoading = true;
      this.$http
        .patch(this.apiUrl.order + "/" + this.$route.params.id + "/cancel")
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch(
            "notification/success",
            "Penjualan Berhasil Dibatalkan"
          );
          this.getDetail();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
    },
    setApproveOrder() {
      this.isLoading = true;
      this.$http
        .patch(this.apiUrl.order + "/" + this.$route.params.id + "/approve")
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch(
            "notification/success",
            "Penjualan Berhasil Dibatalkan"
          );
          this.getDetail();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
    },
    async getDetail() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.order}/${this.$route.params.id}`)
          .then((response) => {
            this.setData = response.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
        if (error.response.status == 404) {
          this.$router.push("/404-notfound");
        }
      }
    },
    openToInvoicePdf() {
      window.open(
        this.apiUrl.baseUrl + "export/order/invoice/" + this.$route.params.id
      );
    },
    openToReceiptPdf() {
      window.open(
        this.apiUrl.baseUrl + "export/order/" + this.$route.params.id
      );
    },
    addDataOrder(cloth) {
      const customerCategoryId = cloth.customer.customer_category.id;
      // const clothId = cloth.id;
      const orderId = this.$route.params.id;
      console.log(cloth);
      return this.$router.push(
        `tambah?use_cloth_detail=1&order_id=${orderId}&customer_category_id=${customerCategoryId}`
      );
    },
    handleRemoveOrder(value) {
      console.log(value);
      this.$swal
        .fire({
          title: "Apakah Kamu Yakin ?",
          text: "Data tidak dapat dikembalikan!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Ya, Hapus!",
        })
        .then((result) => {
          if (result.value) {
            console.log(value);
            this.$http
              .delete(`${this.apiUrl.orderDetail + "/" + value}`)
              .then(() => {
                this.$store.dispatch(
                  "notification/success",
                  "Data berhasil dihapus"
                );
                this.getDetail();
              })
              .catch((error) => {
                this.isLoading = false;
                if (process.env.NODE_ENV !== "production") {
                  console.error(error);
                  if (error.response.status == 404) {
                    this.$router.push("/404-notfound");
                  }
                }
                this.$store.dispatch("notification/error", error);
              });
          }
        });
    },
    returnBack() {
      return this.$router.go(-1);
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <div>
      <b-row v-if="!isLoading">
        <b-card>
          <div class="d-flex justify-content-end align-items-center">
            <div class="m-2">
              <b-button
                @click="openToInvoicePdf()"
                variant="success fw-bold d-flex justify-content-center align-items-center gap-2"
                >Cetak Invoice <i class="bx bx-printer"></i
              ></b-button>
            </div>
            <div class="m-2">
              <b-button
                @click="openToReceiptPdf()"
                variant="info fw-bold d-flex justify-content-center align-items-center gap-2"
                >Cetak Struk <i class="bx bx-printer"></i
              ></b-button>
            </div>
            <div class="m-2">
              <b-button
                v-if="
                  setData.status !== 'Batal' &&
                    $store.getters['account/accountData'].role !== 'kasir' &&
                    setData.enable_to_edit
                "
                @click="setCancleOrder()"
                variant="danger fw-bold d-flex justify-content-center align-items-center gap-2"
                :disabled="isLoading"
                >Batalkan Penjualan <i class="bx bx-trash"></i
              ></b-button>
            </div>
            <div class="m-2">
              <b-button
                v-if="setData.status === 'Pending' && setData.grand_total === 0"
                @click="setApproveOrder()"
                variant="success fw-bold d-flex justify-content-center align-items-center gap-2"
                :disabled="isLoading"
                >Selesaikan Penjualan <i class="bx bx-check"></i
              ></b-button>
            </div>
          </div>
          <b-card-body>
            <b-row>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">No. Order</span>
                <p class="fs-16-custom">{{ setData.order_number }}</p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Pelanggan</span>
                <p class="fs-16-custom">
                  {{ setData.customer && setData.customer.name }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Pembayaran</span>
                <p class="fs-16-custom">
                  {{ setData.payment_method }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Status</span>
                <span
                  class="badge-width fs-6 fw-bold text-white"
                  :class="{
                    'bg-success':
                      setData.status &&
                      setData.status.toLowerCase() === 'lunas',
                    'bg-warning':
                      setData.status &&
                      setData.status.toLowerCase() === 'pending',
                    'bg-danger':
                      setData.status &&
                      setData.status.toLowerCase() !== 'lunas' &&
                      setData.status.toLowerCase() !== 'pending',
                  }"
                >
                  {{ setData && setData.status }}
                </span>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Dibuat Oleh</span>
                <p class="fs-16-custom">
                  {{ setData.created_by && setData.created_by.name }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Diperbarui Oleh</span>
                <p class="fs-16-custom">
                  {{
                    setData.updated_by
                      ? setData.updated_by && setData.updated_by.name
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Tanggal Dibuat</span>
                <p class="fs-16-custom">
                  {{ formatDate(setData.created_at) }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Tanggal Diperbarui</span>
                <p class="fs-16-custom">
                  {{ formatDate(setData.updated_at) }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Total Pembelian</span>
                <p class="fs-16-custom">
                  {{ formatPrice(setData.total) }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Sisa Tagihan</span>
                <p class="fs-16-custom">
                  {{
                    setData.grand_total === 0
                      ? "Rp. " + 0
                      : formatPrice(setData.grand_total)
                  }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Total Kuantitas</span>
                <p class="fs-16-custom">
                  {{ setData.total_qty }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-tabs pills content-class="py-0 text-muted p-0">
          <b-tab active lazy fade class="border-0 p-0">
            <template v-slot:title>
              <span class="">Detail</span>
            </template>
            <b-card>
              <b-card-body>
                <div v-for="(cloth, indexCloth) in setData.cloths">
                  <div
                    class="my-2 d-flex justify-content-between align-items-center"
                  >
                    <p class="m-0 fs-5 fw-bold text-dark">
                      Jenis Pakaian -
                      {{ cloth.cloth_category ? cloth.cloth_category.name : " " }}
                    </p>
                    <b-button
                      v-if="
                        indexCloth == 0 &&
                          setData.status !== 'Batal' &&
                          $store.getters['account/accountData'].role !==
                            'kasir' &&
                          setData.enable_to_edit
                      "
                      @click="addDataOrder(setData)"
                      variant="primary"
                      ><i class="uil uil-plus"></i
                    ></b-button>
                  </div>
                  <table class="table mb-4">
                    <thead>
                      <tr class="table-head">
                        <th>Warna</th>
                        <th>SKU</th>
                        <th class="text-center">Ukuran</th>
                        <th class="text-right">Harga</th>
                        <th class="text-center">Kuantitas</th>
                        <th class="text-right">Total</th>
                        <th
                          class="text-center"
                          width="75"
                          v-if="
                            setData.status !== 'Batal' &&
                              $store.getters['account/accountData'].role !==
                                'kasir' &&
                              setData.enable_to_edit
                          "
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cloth_color in cloth.cloth_colors">
                        <td class="align-middle text-capitalize" width="200">
                          <div class="d-flex align-items-center">
                            <div
                              class="color-indicator"
                              :style="{
                                background: `#${cloth_color.color.code_hexa}`,
                                marginRight: '5px',
                              }"
                            ></div>
                            <span class="">{{ cloth_color.color.name }}</span>
                          </div>
                        </td>
                        <td width="200">
                          {{ cloth_color.sku_code ? cloth_color.sku_code : "-" }}
                        </td>
                        <td class="text-center" width="200">
                          {{ cloth_color.cloth_sizes.size.name }}
                        </td>
                        <td class="text-right" width="200">
                          {{ formatPrice(cloth_color.price) }}
                        </td>
                        <td class="text-center" width="200">
                          {{ cloth_color.qty }}
                        </td>
                        <td class="text-right" width="200">
                          {{ formatPrice(cloth_color.total) }}
                        </td>
                        <td
                          class="text-center"
                          v-if="
                            setData.status !== 'Batal' &&
                              $store.getters['account/accountData'].role !==
                                'kasir' &&
                              setData.enable_to_edit
                          "
                        >
                          <el-button
                            @click="
                              handleRemoveOrder(cloth_color.order_detail_id)
                            "
                            type="danger"
                            icon="el-icon-delete"
                            circle
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              Riwayat Pembayaran
            </template>
            <b-card>
              <b-card-body>
                <b-row align-h="end">
                  <b-col
                    md="6"
                    class="text-right"
                    v-if="
                      setData.status === 'Pending' && setData.grand_total !== 0
                    "
                  >
                    <b-button variant="success" @click="paymentOrderModal()"
                      >Tambah Pembayaran</b-button
                    >
                  </b-col>
                </b-row>
                <table class="table mt-3">
                  <thead>
                    <tr class="table-head">
                      <th class="text-left">Dibuat Oleh</th>
                      <th class="text-left">Catatan</th>
                      <th class="text-center">Tanggal</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="paymentLog in setData.order_payment_log"
                      :key="paymentLog.id"
                    >
                      <td class="text-left" width="200">
                        {{ paymentLog.created_by.name }}
                      </td>
                      <td class="text-left">{{ paymentLog.note }}</td>
                      <td class="text-center">
                        {{ formatDate(paymentLog.created_at) }}
                      </td>
                      <td class="text-right">
                        {{ formatPrice(paymentLog.total) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              History
            </template>
            <b-card>
              <b-card-body>
                <table class="table mt-3">
                  <thead>
                    <tr class="table-head">
                      <th class="text-left" width="200">Dibuat Oleh</th>
                      <th class="text-left">Catatan</th>
                      <th class="text-center">Tanggal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="orderLog in setData.order_history_log"
                      :key="orderLog.id"
                    >
                      <td class="text-left">{{ orderLog.created_by.name }}</td>
                      <td class="text-left">{{ orderLog.note }}</td>
                      <td class="text-center">
                        {{ formatDate(orderLog.created_at) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
        <b-modal
          ref="payment-order-modal"
          centered
          hide-footer
          size="lg"
          title="Tambah Pembayaran"
        >
          <div>
            <form @submit.prevent="paymentOrderSubmit()">
              <div class="mb-3">
                <label for="pembayaran" class="form-label"
                  >Jumlah Pembayaran</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="pembayaran"
                  placeholder="Masukkan Jumlah Pembayaran"
                  v-model="paymentOrder.total"
                />
              </div>
              <div class="mb-3">
                <label for="catatan" class="form-label">Catatan</label>
                <textarea
                  class="form-control"
                  id="catatan"
                  rows="3"
                  placeholder="Masukkan Catatan (Opsional)"
                  v-model="paymentOrder.note"
                ></textarea>
              </div>
              <!-- <div class="mb-3">
                  <label for="formFileUpload">Masukkan Photo</label>
                  <b-form-file
                    v-model="paymentOrder.attachment"
                    id="formFileUpload"
                    accept="image/*"
                  ></b-form-file>
                </div> -->
              <b-button type="submit" variant="success">Kirim</b-button>
            </form>
          </div>
        </b-modal>
        <!-- <b-tabs pills content-class="py-2 text-muted">
            <b-tab active lazy fade class="border-0">
              <template v-slot:title>
                <span class="">Item</span>
              </template>
              <b-card>
                <b-card-body>
                  list item
                </b-card-body>
              </b-card>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span>Riwayat Pembayaran</span>
              </template>
              <b-card>
                <b-card-body>
                  list riwayat pembayaran
                </b-card-body>
              </b-card>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                History
              </template>
              <b-card>
                <b-card-body>
                  list history
                </b-card-body>
              </b-card>
            </b-tab>
          </b-tabs> -->
      </b-row>
      <b-row v-else>
        <b-card>
          <div class="d-flex justify-content-end align-items-center">
            <div class="m-2">
              <skeletonLoading width="110px" height="35px" borderRadius="7px" />
            </div>
            <div class="m-2">
              <skeletonLoading width="90px" height="35px" borderRadius="7px" />
            </div>
          </div>
          <b-card-body>
            <b-row>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="2">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col>
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="80"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-body>
            <skeletonLoading
              width="15%"
              height="30px"
              class="mt-2"
              borderRadius="4px"
            />
            <table
              class="table mt-2"
              v-for="cloth in setData.cloths"
              :key="cloth.id"
            >
              <skeletonLoading
                width="100%"
                height="250px"
                class="mt-2"
                borderRadius="4px"
              />
            </table>
          </b-card-body>
          <b-card-body>
            <skeletonLoading
              width="15%"
              height="30px"
              class="mt-2"
              borderRadius="4px"
            />
            <table
              class="table mt-2"
              v-for="cloth in setData.cloths"
              :key="cloth.id"
            >
              <skeletonLoading
                width="100%"
                height="200px"
                class="mt-2"
                borderRadius="4px"
              />
            </table>
          </b-card-body>
          <b-card-body>
            <skeletonLoading
              width="15%"
              height="30px"
              class="mt-2"
              borderRadius="4px"
            />
            <table
              class="table mt-2"
              v-for="cloth in setData.cloths"
              :key="cloth.id"
            >
              <skeletonLoading
                width="100%"
                height="200px"
                class="mt-2"
                borderRadius="4px"
              />
            </table>
          </b-card-body>
        </b-card>
      </b-row>
    </div>
  </Layout>
</template>

<style scoped>
.vertical-align {
  vertical-align: middle;
}
.table-bordered {
  border-color: #e3e3e3 !important;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.table-head {
  background-color: #f5f7fa;
  height: 60px;
  vertical-align: middle;
  color: #2f2f2f;
  font-weight: 900;
  width: 500px;
}
.activity-feed .feed-item:last-child {
  border-color: #ebeef5 !important;
}
.badge-width {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
}

.table th,
.table tr,
.table td {
  border: 1px solid #c0c4cc !important;
  vertical-align: middle !important;
}
</style>
